import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Spinner} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {resetVerifyCode, verifyAccount} from '../store/authentication/authCrud';
import {User} from '../model/user/User';
import {ErrorToast, SuccessToast} from '../utils/toasters';
import {actions} from '../store/authentication/authRedux';
import {BASE_URL} from '../utils/Endpoints';

export function VerificationPage() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const {user} = useSelector((state: any) => state?.auth);

    const [confirmationToken, setConfirmationToken] = useState({
        twoFactorToken: '',
    });

    const resendVerify = () => {
        setIsLoading(true);
        resetVerifyCode(user?.email ?? '').then(response => {
            SuccessToast('Please check your email for the verification code');
        }).catch(error => ErrorToast(error))
            .finally(() => setIsLoading(false));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setConfirmationToken(prev => ({...prev, [name]: value}));
    };

    function onSubmit(event: React.FormEvent<HTMLFormElement | EventTarget & HTMLElement>) {
        event.preventDefault();
        verifyAccount(user.twoFactorUUID, confirmationToken.twoFactorToken).then((data) => {
            dispatch(actions.verify())
            history.push('/')
        }).catch(err => ErrorToast(err));
    }

    return (
        <Card className="card-custom mb-5">
            <Form as={Card.Body}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <img alt="Verifaid img" src="/icons/verified.png"/>
                    <h3 className="mt-5">Verify your account</h3>
                    <p className="text-muted">Enter the code received in your E-Mail:<br/>{user?.email}</p>
                </div>
                <Form.Row>
                    <Form.Group as={Col} className='mt-4 mb-0'>
                        <Form.Control required
                                        placeholder="Code*"
                                        min={4}
                                        type="number"
                                        defaultValue={confirmationToken?.twoFactorToken}
                                        name="twoFactorToken"
                                        onChange={handleChange}
                        />
                    </Form.Group>
                </Form.Row>
                <div>
                    <span className="text-muted">
                        Do not forget to check your Spam Folder.
                    </span>
                    <div className="d-flex justify-content-between align-items-center my-5">
                        <a className="d-flex align-items-center justify-content-end"
                            onClick={() => resendVerify()}>
                            {isLoading ? <>
                                Loading... <Spinner animation="border" size="sm" role="status"
                                                    className="ml-1"/>
                            </> : 'Re-Send'}
                        </a>
                        <Button disabled={confirmationToken.twoFactorToken.length < 4} type='submit'
                                onClick={(event) => onSubmit(event)} variant="outline-primary"
                                size="sm">Verify</Button>
                    </div>
                </div>
            </Form>
        </Card>
    )
};