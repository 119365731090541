import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {toast} from 'react-toastify';
import {Campaign} from '../../../model/campaign/Campaign';
import {User} from "../../../model/user/User";
import {ReportsService} from "../ReportsService";
import {IReportItem} from "../../../model/report/Report-item";
import {FaCheck, FaTimes} from "react-icons/fa";
import {FiInfo} from "react-icons/all";
import {formatDate} from "../../../utils/Functions";
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import AutocompleteSelect from "../../../shared/components/AutocompleteSelect";
import {CampaignsService} from "../../campaigns/CampaignsService";
import {UserService} from "../../user/UserService";
import {ErrorToast, SuccessToast} from "../../../utils/toasters";
import {InvoiceService} from "../../invoices/InvoicesService";
import {NavLink} from "react-router-dom";

export default function ReportsList() {
	const [pagination, setPagination] = useState({
		page: 1,
		perPage: 15,
	});
	const [totalPages, setTotalPages] = useState(0);
	const [reportList, setReportList] = useState<IReportItem[]>([]);
	const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

	const [campaign, setCampaign] = useState<Campaign[]>([]);
	const [campaignId, setCampaignId] = useState('');

	const [users, setUsers] = useState<User[]>([]);
	const [userId, setUserId] = useState('');

	useEffect(() => {
		CampaignsService.getCampaigns({ perPage: 10000 })
			.then((data) => {
				setCampaign(data.data);
			})
			.catch((err) => {
				toast.error(`${err.response.data.message}`);
			});

		UserService.getUsers({ perPage: 20000, userType: 'influencer' })
			.then((data) => {
				setUsers(data.data);
			})
			.catch((err) => {
				toast.error(`${err.response.data.message}`);
			});
	}, []);

	useEffect(() => {
		ReportsService.getReports({ ...pagination, campaignId: campaignId, userId: userId})
			.then((data) => {
				setReportList(data.data);
				setTotalPages(Math.ceil(data.count / pagination.perPage));
			})
			.catch((err) => {
				toast.error(`${err?.response?.data?.message}`);
			});
	}, [pagination, triggerSearch, userId, campaignId]);

	function handleCheckbox(id: number) {
		ReportsService.toggleVerified(id);
		const data = reportList.map((e) => {
			if(e.id === id) return {...e, isVerified: !e.isVerified}
			return e;
		});
		setReportList(data);
	}


	const handlePages = (updatePage: number) => {
		setPagination({ ...pagination, page: updatePage });
	};

	function handleChange(evt: any) {
		const value = evt.target.value;
		setPagination({
			...pagination,
			[evt.target.name]: value,
		});
	}

	return (
		<div>
			<div className="card card-custom mb-5 p-4 mt-3" 
				 onKeyDown={(e) => {
					if (e.key === "Enter") setTriggerSearch(!triggerSearch);
				 }}
			>
				<Form.Row>
					<Form.Group as={Col}>
						<AutocompleteSelect
							className="form-control-solid"
							list={campaign}
							listKey={'title'}
							updateState={setCampaignId}
							placeholder={'Campaign title'}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<AutocompleteSelect
							className="form-control-solid"
							list={users}
							listKey={'displayName'}
							updateState={setUserId}
							placeholder={'Displayname'}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Control placeholder="Additional" className="form-control-solid" name="isAdditional" onChange={handleChange} as="select">
							<option value="undefined">is Additional?</option>
							<option value="true">Additional</option>
							<option value="false">Not Additional</option>
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Control placeholder="Additional" className="form-control-solid" name="isVerified" onChange={handleChange} as="select">
							<option value="undefined">is Verified?</option>
							<option value="true">Verified</option>
							<option value="false">Not Verified</option>
						</Form.Control>
					</Form.Group>
				</Form.Row>
				<div>
					<Button
						type="submit"
						onClick={() => setTriggerSearch(!triggerSearch)}
					>
						Search
					</Button>
				</div>
			</div>
			<Card className="mb-5 p-4 mt-5">
				<Table hover responsive>
					<thead>
					<tr>
						<th>Report ID</th>
						<th>Date</th>
						<th>Campaign ID</th>
						<th>Offer ID</th>
						<th>Influencer ID</th>
						<th>Platform</th>
						<th>Screenshot</th>
						<th>is Verified?</th>
						<th>is Additional?</th>
						<th>Action</th>
					</tr>
					</thead>
					<tbody>
					{reportList.map((item) => (
						<tr key={item.id}>
							<td>{item?.id}</td>
							<td>{formatDate(item?.createdAt)}</td>
							<td>{item?.offer?.campaign?.id}
								<OverlayTrigger placement="top"
												overlay={<Tooltip
													id="campaign-tooltip">
													{item?.offer?.campaign?.title}
												</Tooltip>}>
									<FiInfo size={14} className="cursor-pointer ml-1"/>
								</OverlayTrigger>
							</td>
							<td>{item?.offer?.id}</td>
							<td><ImpersonateEye id={item?.offer?.user?.id}/>{item?.offer?.user?.displayName}</td>
							<td>                                {item?.socialPlatform ?
								<img alt='' src={item?.socialPlatform?.path} style={{width: 40, height: 40}}
									 className="rounded"/>
								: <img alt='' src="/images/placeholder.png" style={{width: 40, height: 40}}
									   className="rounded"/>}</td>
							<td>                                {item?.reportImages?.length ?
								<img alt='' src={item?.reportImages[0]?.reportPath} style={{width: 100, height: 60}}
									 className="rounded"/>
								: <img alt='' src="/images/placeholder.png" style={{width: 70, height: 60}}
									   className="rounded"/>}</td>
							<td>{item?.isVerified ?
								<span className="word-break text-primary"><FaCheck/></span> :
								<span className="word-break text-danger"><FaTimes/></span>}
							</td>
							<td>{item?.additionalPost ?
								<span className="word-break text-primary"><FaCheck/></span> :
								<span className="word-break text-danger"><FaTimes/></span>}
							</td>
							<td>
								<NavLink className="btn btn-primary" to={{
									pathname: '/reported/edit',
									state: {id: item.id}
								}}>Edit</NavLink>
							</td>
						</tr>
					))}
					</tbody>
				</Table>
				<div className="w-100">
					{totalPages <= 1 ? (
						''
					) : (
						<Pagination
							page={pagination.page}
							totalPages={totalPages}
							handlePagination={handlePages}
						/>
					)}
				</div>
			</Card>
		</div>
	);
}
